<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        ref="refForm"
        :model="formData"
        label-width="150px"
        :rules="formRules"
        :loading="formLoading"
      >
        <el-form-item label="城市仓：" prop="logistics_id">
          <el-select
            v-model="formData.logistics_id"
            @change="onChangeCityStore"
            :loading="loadingSelfLogisticsList"
            :disabled="disabledData"
            filterable
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="`${item.name} 【${
                item.logistics_business && item.logistics_business.bank
                  ? item.logistics_business.bank.username
                  : '-'
              }】`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="自提点：">
          <el-select
            placeholder="选择自提点"
            v-model="formData.delivery_id"
            :loading="loadingSelfTakeList"
            :disabled="disabledData"
            clearable
          >
            <el-option
              v-for="item in selfTakeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="扣款周期：" prop="start_date">
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="扣款日期起"
            end-placeholder="扣款日期止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            align="right"
            v-model="dataTime"
            @change="changeDate"
            :picker-options="pickerOptions"
            :disabled="disabledData"
          >
          </el-date-picker>
        </el-form-item>

        <el-row v-for="(item, i) in formData.deduction_data" :key="i">
          <div class="positions">
            <el-form-item
              label="扣款类型："
              :prop="`deduction_data.${i}.deduction_type`"
              :rules="DataRules.deduction_type"
            >
              <el-select
                v-model="item.deduction_type"
                placeholder="选择扣款类型"
                @change="typeChange($event, item, i)"
                :disabled="disabledData"
                clearable
              >
                <el-option
                  v-for="item1 of deductionTypeList"
                  :key="item1.id"
                  :label="item1.name"
                  :value="item1.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="金额："
              :prop="`deduction_data.${i}.deduction_amt`"
              :rules="DataRules.deduction_amt"
            >
              <el-input
                v-model="item.deduction_amt"
                :disabled="disabledData"
                placeholder="输入金额"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item
              v-if="item.deduction_type === SALE_DEDUCTION_TYPE"
              label="售后单号："
              :prop="`deduction_data.${i}.as_no`"
              :rules="DataRules.as_no"
            >
              <el-input
                v-model="item.as_no"
                :disabled="disabledData"
                placeholder="输入售后单号"
                clearable
              >
              </el-input>
            </el-form-item>

            <p class="tooltip" v-if="!disabledData">
              扣款类型为二次售后扣款且关联售后单号后将给用户线上退款，请谨慎操作
            </p>

            <div class="position-subset">
              <i
                class="el-icon-circle-plus"
                v-if="formData.deduction_data.length == i + 1"
                @click="addproduct"
              ></i>
              <i
                class="el-icon-delete-solid"
                v-if="formData.deduction_data.length != 1"
                @click="delproduct(i)"
              ></i>
            </div>
          </div>
        </el-row>

        <el-form-item label="备注：" prop="remarks">
          <el-input
            type="textarea"
            v-model="formData.remarks"
            placeholder="输入备注"
            maxlength="120"
            :disabled="disabledData"
            :rows="3"
            show-word-limit
          >
          </el-input>
        </el-form-item>

        <el-form-item label="扣款证明：">
          <b-file-upload
            :accepts="['jpg', 'jpeg', 'png']"
            :limit="1"
            :disabled="disabledData"
            v-model="picList"
          ></b-file-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="!disabledData" @click="onHandleClose">取消</el-button>
      <el-button
        type="primary"
        @click="onHandleSubmit"
        :disabled="disabledSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import { validMoney } from "@/utils/tools/validate";
// import {
//   HEAD_TYPE_ENUM,
//   STOCK_OUT_REASON_SELECT,
// } from "../../utils/enum/index";
import { postLogisticsDeductionbatchSave } from "@/api/general/operation-center/index";
import { CHECK_STATUS } from "../../utils/enum/index";
// import { formatDate, toDate } from "@/utils/tools/date.js";

export default {
  name: "batchDialog",
  components: {
    BFileUpload,
  },
  props: {
    /**
     * 扣款类型下拉
     */
    deductionTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      CHECK_STATUS,
      dialogVisible: false,
      loadingSelfLogisticsList: false,
      loadingSelfTakeList: false,
      disabledSubmit: false,
      disabledData: false,
      formLoading: false,
      SALE_DEDUCTION_TYPE: 80, // 二次扣款类型ID
      titleDialog: "新增",
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      dataTime: [], // 时间对象
      picList: [], // 图片列表
      formData: {
        deduction_data: [
          {
            deduction_type: "", // 扣款类型
            deduction_amt: "", // 扣款金额
            as_no: "", // 售后单号
          },
        ],

        logistics_id: "", // 城市仓id
        delivery_id: "", // 自提点ID
        start_date: "", // 扣款周期起
        end_date: "", // 扣款周期止
        remarks: "", // 备注
        pic: "", // 扣款证明
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      deduction_id: "", // 编辑该数据ID
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, // 时间范围
      DataRules: {
        deduction_type: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择扣款类型！",
          },
        ],
        deduction_amt: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入金额！"));
              }
              if (!Number(value) || !validMoney(value)) {
                callback(new Error("大于0，允许两位小数点"));
              }
              callback();
            },
          },
        ],
        as_no: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入售后单号！"));
              }
              callback();
            },
          },
        ],
      },
      formRules: {
        logistics_id: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择城市仓！",
          },
        ],
        start_date: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              const { start_date, end_date } = this.formData;
              if (!start_date || !end_date) {
                callback(new Error("请选择扣款周期！"));
              }
              callback();
            },
          },
        ],

        remarks: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请输入备注！",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    /**
     * 图片发生改变
     */
    picList() {
      const res = this.picList?.map((item) => item.url).join(",");
      this.formData.pic = res;
    },
  },
  methods: {
    addproduct() {
      if (this.formData.deduction_data.length == 5) {
        this.$message.error("最多只能添加五条");
        return;
      }

      this.formData.deduction_data.push({
        deduction_type: "", // 扣款类型
        deduction_amt: "", // 扣款金额
        as_no: "", // 售后单号
      });
    },
    delproduct(i) {
      this.formData.deduction_data.splice(i, 1);
    },
    typeChange(e, item, i) {
      if (item.deduction_type !== this.SALE_DEDUCTION_TYPE) {
        this.formData.deduction_data[i].as_no = "";
      }
    },
    /**
     * 初始化
     */
    async onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      this.ajaxGetCityStoreList();
      data && this.setEditData(data);
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      this.loadingSelfLogisticsList = true;
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingSelfLogisticsList = false;
      }
    }, 300),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function (logisticsId) {
      this.loadingSelfTakeList = true;
      const params = {
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.deliveryList(params);
        this.selfTakeList = data.data;
        console.log("ajx getAjaxSelfTakeList", data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      } finally {
        this.loadingSelfTakeList = false;
      }
    }, 300),
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // 重置自提点
      this.selfTakeList = [];
      this.formData.delivery_id = "";
      this.getAjaxSelfTakeList(data);
    },
    /**
     * 日期发生了变化
     */
    changeDate(data) {
      this.formData.start_date = data?.[0] || "";
      this.formData.end_date = data?.[1] || "";
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.dataTime = [];
      this.picList = [];
      this.titleDialog = "新增";
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
      this.disabledData = false;
    },

    /**
     * 确认
     */
    onHandleSubmit() {
      // 如果为只读, 则直接关闭
      if (this.disabledData) {
        this.onHandleClose();
        return;
      }
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }

        this.ajaxPostUpdateData();
      });
    },
    /**
     * 提交
     */
    async ajaxPostUpdateData() {
      const params = {
        ...this.formData,
      };
      this.disabledSubmit = true;
      try {
        await postLogisticsDeductionbatchSave(params);
        this.$message.success("操作成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postLogisticsDeductionbatchSave err", err);
      } finally {
        this.disabledSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  /deep/ .el-form {
    margin-left: 50px;
    width: 90%;
    // &-item__label {
    //   width: 150px;
    // }
    .el-textarea {
      .el-input__count {
        bottom: -5px;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  .el-input,
  .el-select,
  .el-textarea {
    width: 400px;
  }
  .tooltip {
    // position: relative;
    // left: 13%;

    margin: 20px 0;
    margin-left: 13%;
    color: $danger-color;
  }
}
.positions {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 40px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.position-subset {
  position: absolute;
  right: 4%;
  top: 38%;
  .el-icon-circle-plus {
    font-size: 45px;
  }
  .el-icon-delete-solid {
    font-size: 45px;
  }
}
</style>
